import Head from 'next/head';
import '../global.css';
import type { AppProps } from "next/app";

// import "tailwindcss/tailwind.css";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Fonts />
      <div className="font-body-1">
        <Component {...pageProps} />
      </div>
    </>
  );
}

function Fonts() {
  return (
    <>
      <Head>
        <>
          {/* Global Site Tag (gtag.js) - Google Analytics */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
          />
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
            }}
          />
        </>
        <>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="crossorigin"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Balsamiq+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Bangers&family=Boogaloo&family=Gluten:wght@100;200;300;400;500;600;700;800;900&family=Luckiest+Guy&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Varela+Round&display=swap"
            rel="stylesheet"
          />
          <style>{`
          .font-display-1 {
            font-family: "Balsamiq Sans", sans-serif;
          }
          .font-body-1 {
            font-family: "Poppins", sans-serif;
          }
        `}</style>
        </>
        <>
          <meta name="title" content="Sims Shellcaster" />
          <meta
            name="description"
            content="Generate house layouts for The Sims shell challenges! Shell challenges provide a fun constraint to inspire a creative Sims build."
          />
          <meta
            name="keywords"
            content="the sims, sims, gaming, shell challenge, generator, builder, house, layout, blueprint"
          />
          <meta name="robots" content="index, follow" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <meta
            name="author"
            content="Jessica Baker, Jordan Erica Webber, Topher Winward"
          />
        </>
        <title>Sims Shellcaster</title>
      </Head>
    </>
  );
}

export default MyApp;
